import React, { useEffect, useState } from "react"

import { Router, Route } from "@reach/router"

import ActivateAccount from "./ActivateAccount";
import NotFoundPage from "../404";

const Activate = (props) => {

    // const currentStep = useSelector(state => state.checkout.currentStep);

    return (
        <div className="">
            <Router basepath={"/activate"}>
                <ActivateAccount path="/:email/:key" />
                <NotFoundPage default />
            </Router>
        </div>
    )
}

export default Activate;
