import * as React from "react"


import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

import * as formStyles from "../../scss/form.module.scss"
import axios from "axios"
import { Link, navigate } from "@reach/router"
import LoadingSpinner from "../../components/loadingSpinner";


const ActivateAccount = (props) => {
    const [showInvCred, setShowInvCred] = useState(false);

    console.log(props);

    useEffect(() => {

        let valid = true;

        // if (props.params === undefined) valid = false;
        // if (props.params.email === undefined) valid = false;
        // if (props.params.key === undefined) valid = false;
        // if (props.params === null) valid = false;
        // if (props.params.email === null) valid = false;
        // if (props.params.key === null) valid = false;

        if (props.email === undefined) valid = false;
        if (props.key === undefined) valid = false;
        if (props.email === null) valid = false;
        if (props.key === null) valid = false;

        if (!valid) {
            setShowInvCred(true);
            return false;
        }

        let config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/activate',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                email: props.params.email,
                key: props.params.key
            }
        };

        axios(config)
            .then((response) => {
                console.log("The axios login response", response);
                if (response.data.c == 200) {

                    localStorage.setItem("user", response.data.token);
                    let videoProgress = JSON.stringify(response.data.videoProgress);
                    localStorage.setItem("videoProgress", videoProgress);
                    navigate('/modules/');

                }
                else {
                    setShowInvCred(true);
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });

    })



    return (
        <>

            <LoadingSpinner
                style={{ marginTop: '45vh' }} />
            {
                showInvCred && (
                    <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                        Etwas ist schief gelaufen. Bitte versuche es erneut.
                    </p>
                )
            }

        </>
    )
}

export default ActivateAccount